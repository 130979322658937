import React from 'react'
import {Link} from 'gatsby'
import _ from 'lodash'
import styled from 'styled-components'

import Layout, {media} from '../components/layout'
import Seo from '../components/seo'
import Chart from '../components/chart'

import coins from '../data/data_handler'
import charts from '../data/chart_handler'


const CoinList = () => {

    return (
        <Layout>
            <Seo title='仮想通貨一覧'/>
            <ChartWrapper>
                {_.map(coins, coin =>
                    <Link to={`/coins/${coin['symbol']}`}>
                        <Chart coin={coin} chart={charts[coin['symbol']]}/>
                    </Link>
                )}
            </ChartWrapper>
        </Layout>
    )
}


export default CoinList


const Frame = styled.section`
    border-radius: 0.625vw;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 40 / 1280 ) *100vw );
    // width: calc(( 1000 / 1280 ) *100vw );
    // max-width: 1000px;
    margin: calc(( 75 / 1280 ) *100vw ) auto 0 auto;
    background-color: #fff;
    
    ${media.small`
        padding: calc((100vw / 750 ) * 100 ) calc((100vw / 750 ) * 20 ) calc((100vw / 750 ) * 60 ) calc((100vw / 750 ) * 20 );
        margin: calc((100vw / 750 ) * 80 ) calc((100vw / 750 ) * 40 ) calc((100vw / 750 ) * 40 );
        border-radius: 10px;
    `}   
`

const ChartWrapper = styled(Frame)`
    display: grid;
    grid-template-columns: 160px 160px 160px 160px;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    
    ${media.small`
        grid-template-columns: 160px 160px;
    `} 
    
    ${media.medium`
        grid-template-columns: 160px 160px 160px;
    `} 
    
    ${media.xlarge`
        grid-template-columns: 160px 160px 160px 160px 160px 160px;
    `} 
`